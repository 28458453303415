<template>
  <div class="nav-pills-wrapper">
    <ul class="nav nav-pills">
      <li role="presentation" v-for="(item, index) in items" :key="index" :class="{'active': item.value === value}"><a href="javascript:void(0)" @click="onClickItem(item, $event)">{{item.label}}</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: []
    },
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClickItem(item) {
      this.$emit("change", item)
    }
  }
}
</script>

<style lang="scss">

.nav-pills {

  & > li {
    margin: 8px 4px;
  }

  & > li > a {
    padding: 5px 15px;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    border-radius: 4px;
    overflow: hidden;
  }

  & > li > a:focus,
  & > li > a:hover {
    background-color: #dcdcdc;
  }

  & > li.active > a,
  & > li.active > a:focus,
  & > li.active > a:hover {
    font-size: 16px;
    color: #fff;
    background-color: #333333;
  }
}


</style>
