<template>
  <div class="pagination-wrapper">
    <ul class="pagination">
      <li class="page-item" :class="{'disabled': page === 1 ? true : false}"><a class="page-link" href="javascript:void(0)" @click="onClickPage('first')">‹‹</a></li>
      <li class="page-item" :class="{'disabled': page === 1 ? true : false}"><a class="page-link" href="javascript:void(0)" @click="onClickPage('prev')">‹</a></li>
      <li class="page-item" v-for="(item, index) in items" :key="item.page" :class="{'active': item.active}" ><a class="page-link" href="javascript:void(0)" @click="onClickPage(item.page)">{{item.page}}</a></li>
      <li class="page-item" :class="{'disabled': page === this.limit ? true : false}"><a class="page-link" href="javascript:void(0)" @click="onClickPage('next')">›</a></li>
      <li class="page-item" :class="{'disabled': page === this.limit ? true : false}"><a class="page-link" href="javascript:void(0)" @click="onClickPage('last')">››</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 20
    },
    groupSize: {
      type: Number,
      default: 5
    },
    total: {
      type: Number,
      default: 20
    }
  },
  computed: {
    limit() {
      return this.total <= 0 ? 1 : Math.ceil(this.total / this.size)
    },
    items() {
      let start = Math.max( 1, Math.min(this.limit - (this.groupSize - 1), Math.floor((this.page - 1) / this.groupSize) * this.groupSize + 1) )
      let max = Math.min(start + (this.groupSize - 1), this.limit)
      let items = []
      for ( var i=start; i<=max; i ++ ) {
        items.push({
          active: this.page === i ? true : false,
          page: i
        })
      }
      return items
    }
  },
  methods: {
    onClickPage(page) {
      if (page === 'first') {
        this.$emit("page", {
          page: 1
        })
      }
      else if (page === 'last') {
        this.$emit("page", {
          page: this.limit
        })
      }
      else if (page === 'prev') {
        this.$emit("page", {
          page: Math.max(1, this.page - 1)
        })
      }
      else if (page === 'next') {
        this.$emit("page", {
          page: Math.min(this.limit, this.page + 1)
        })
      }
      else if (typeof page === 'number') {
        this.$emit("page", {
          page: page
        })
      }
    }
  }
}
</script>
