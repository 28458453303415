<template>
<div class="wrapper wrapper-content basic list">
  <div class="ibox ">

    <div class="ibox-content">

      <div class="table-responsive">
        <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
          <thead>
            <tr>
              <th :colspan="fields.length" style="font-size: 1rem; border: solid 1px #ebebeb; box-shadow: 2px 0px 1px 0px #e5e5e5; background-color: #e5e5e5;">
                에이전트 코드 : {{agent_code}}
              </th>
            </tr>
            <tr>
              <th :colspan="fields.length">
                <!--   -->
                <SearchInput v-model="keyword" @submit="onSearch(keyword, filter)" />
                <div class="search-input" style="margin-right: 0.5rem">
                  <select v-model="filter" class="form-control" >
                    <option value="all">전체</option>
                    <!-- <option value="host_id">HOST ID</option>
                    <option value="host_id">USER ID</option> -->
                  </select>
                </div>
              </th>
            </tr>

            <tr class="info">
              <th :colspan="fields.length">
                <div class="info">Showing {{page}} to {{Math.max(1, parseInt(total/size))}} of <strong>{{total}}</strong> entries</div>

                <div class="utils">
                  <button v-if="useExport" class="btn btn-util">
                    <export-excel :name="exportName" :export-fields="exportFields" :data="exportItems" :before-generate="loadExport"><svg-icon name="excel"></svg-icon> Export to Excel</export-excel>
                  </button>
                </div>
              </th>
            </tr>

            <tr class="fields">
              <template v-for="field in fields.filter( o => !o.view || o.view == groupId)">
                <th scope="col" :key="field.key">{{field.label}}</th>
              </template>
            </tr>
          </thead>

          <tbody>

            <template>
              <tr v-for="(row, i) in items" :key="i">
                <td>{{ row.uid }}</td>
                <td>{{ row.user_id }}</td>
                <td>{{ row.host_name }}</td>
                <td>{{ row.host_phone }}</td>
                <td>{{ $moment(row.linked_at).format('YYYY-MM-DD HH:mm') }}</td>
                <template v-for="(field) in fields">
                  <template v-if="visible(field, 'action')">
                    <td :key="field.key" class="actions">
                      <button v-for="action in field.actions"
                        :key="action.action"
                        :disabled="!blockTransactionActions({...row, action})"
                        class="btn btn-xs" :class="getAction(action).class"
                        :style="{display: hideByTransactionActions({...row, action}) ? 'none' : ''}"
                        @click="getAction(action).onAction(row, { $router, $store, model, $dialog, $toast}, loadData)">{{getAction(action).label}}</button>
                    </td>
                  </template>
                </template>
              </tr>
            </template>
            

            <template v-if="!items.length">
              no data
            </template>

            <div class="loading" v-if="loading"> <loader type="square-scale-ripple" /> </div>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="fields.length" class="text-left">
                <Pagination :page="page" :size="size" :total="total" @page="onPage" />

                <div class="actions">
                  <div class="right">
                    <button v-for="action in actions"
                      :key="action.action"
                      class="btn btn-xs" :class="getAction(action).class"
                      @click="getAction(action).onAction({ $router, $store, model })">
                        <fa-icon :icon="getAction(action).icon" v-if="getAction(action).icon" />
                        {{getAction(action).label}}
                      </button>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

    </div>
  </div>
</div>
</template>

<script>

import Pills from "@/components/elements/Pills"
import Pagination from "@/components/elements/Pagination"
import SearchInput from "@/components/utils/SearchInput"
import FilterValue from "@/components/utils/FilterValue"
import utils from "@/utils"

export default {
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      items: [],
      agent_code : '',
      fields: [
        {label: 'Host ID', value: 'uid'},
        {label: 'User ID', value: 'user_id'},
        {label: 'Host Name', value: 'host_name'},
        {label: 'Host Phone', value: 'host_phone'},
        {label: 'Linked At', value: 'linked_at'},
        { key: 'action', type: 'action', label: 'Actions', listing: true, 
          actions: ["delete"], hidden: true 
        }
      ],
      actions: [],

      groupId: '',
      groups: [
        // { label: "초대한 유저", value: "invited" },
        // { label: "구매한 유저", value: "paid" }
      ],

      filter: '',
      keyword: '',
      query: {},
      loading: false,

      showExport: false,
      exportItems: [],
      exportDays: 0,
      exportDateStart: '',
      exportDateEnd: ''
    }
  },
  async created() {
    this.model = this.$route.meta.model
    this.$data.filter = this.$route.query.filter || 'all'
    this.$data.groupId = this.$route.query.groupId || 'invited'
    this.$data.agent_code = this.$route.query.agent_code
    this.$data.page = this.$route.query.page && parseInt(this.$route.query.page) || this.page
    this.$data.size = this.$route.query.size && parseInt(this.$route.query.size) || this.size
    this.$data.role_name = this.$route.params.role_name;

    await this.loadData()

  },
  computed: {
    useExport() {
      return this.model.types.export ? true : false
    },
    useExportJson() {
      return this.model.types.exportJson ? true : false
    },
    exportName() {
      return `${this.$data.groupId}.${this.$moment().format('YYYYMMDD')}.xls`
    },
    exportFields() {
      if (this.model.types[this.$data.groupId + 'Export']) {
        return this.model.types[this.$data.groupId + 'Export'].fields
      }
      return {}
    }
  },
  methods: {
    loadData(options = {}) {
      this.$data.loading = true
      this.$store.dispatch(this.model.types.hostList.action, {
        page: options.page || this.page,
        size: options.size || this.size,
        uid: this.$route.params.uid,
        agent_code: options.agent_code || this.agent_code
        })
        .then((data) => {
          let { items, total } = data

          this.$data.items = items
          this.$data.total = total
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.loading = false
        })
    },

    loadExport() {
      if(this.groupId == 'paid'){
        if (this.$data.items.length === 0) {
            this.$toast.error("추출할 데이타가 없습니다.")
            return;
        }

        let items = this.$data.items.map( o => {
          o.ticket_date = this.$moment(o.ticket_date_start).format('YYYY-MM-DD HH:mm') + "~" + this.$moment(o.ticket_date_end).format('MM-DD HH:mm')
          o.reserved_at = this.$moment(o.reserved_at).format('YYYY-MM-DD HH:mm')
          return o
        })
        this.$data.exportItems = items
        return items;
      }

      let params = {
        group: this.groupId,
        date_start: this.exportDateStart,
        date_end: this.exportDateEnd,
        uid: this.$route.params.uid
      }

      return new Promise((resolve, reject) => {
        this.$store.dispatch(this.model.types[this.$data.groupId+'Export'].action, params)
          .then((data) => {
            let { items, fields } = data

            // console.log( 'items', items )
            if (items.length === 0) {
              this.$toast.error("추출할 데이타가 없습니다.")
            }

            this.$data.exportItems = items
            resolve(data)
          })
          .catch(e => {
            this.$toast.error(e.message)
            reject(e)
          })
      })
    },

    async requestData(options = {}) {
      await this.loadData(options);

      let { page, size, keyword, filter, code } = options
      this.$data.page = page || this.page
      this.$data.size = size || this.size
      this.$data.keyword = keyword || this.keyword
      this.$data.filter = filter || this.filter
      this.$data.code = code || this.code
      this.$router.push({
        query: {
          page: this.page,
          size: this.size,
          keyword: this.keyword,
          filter: this.filter,
          groupId: this.groupId,
          code: this.code
        }
      })
    },

    blockTransactionActions(options) {
      let { status, action } = options            

      if (typeof action === 'object'){
        action = action.action;
      }      

      if(this.$route.name === 'ListTransaction'){
        
        if(status.toLowerCase() === 'paid' && action === 'cancel'){
          return true;
        }

        if(status.toLowerCase() === 'fail' && action === 'complete'){          
          return true;
        }

        if(status.toLowerCase() === 'cancelled' && action === 'complete'){
          return true;
        }

        return false;
      }else{
        return true;
      }
    },

    hideByTransactionActions( options = {}){
      let { status, action } = options            

      if (typeof action === 'object'){
        action = action.action;
      }
      
      if(this.$route.name === 'ListTransaction'){
        
        if(status.toLowerCase() === 'fail' && action === 'cancel'){
          return true;
        }

        if(status.toLowerCase() === 'paid' && action === 'complete'){
          return true;
        }

        if(status.toLowerCase() === 'cancelled'){
          return true;
        }

        if(status.toLowerCase() === 'completed'){
          return true;
        }

        return false;
      }else{
        return false;
      }
    },

    visible(field, type) {
      if (field.type === type) {
        return true
      }

      if (type === '*') {
        return !['uid', 'datetime', 'resource', 'action'].includes(field.type)
      }

      return false
    },

    getAction(action) {
      if (action === 'create') {
        return {
          action: "create",
          class: "btn-danger",
          icon: "edit",
          label: "생성",
          onAction: this.onClickCreate
        }
      }
      else if (action === 'edit') {
        return {
          action: "edit",
          class: "btn-success",
          label: "수정",
          onAction: this.onClickEdit
        }
      }
      else if (action === 'delete') {
        return {
          action: "delete",
          class: "btn-danger",
          label: "삭제",
          onAction: this.onClickDelete
        }
      }
      else if (action === 'view') {
        return {
          action: "view",
          class: "btn-info",
          label: "보기",
          onAction: this.onClickView
        }
      }
      else if (action === 'cancel') {
        return {
          action: "cancel",
          class: "btn-warning",
          label: "취소",
          onAction: this.onClickCancel
        }
      }
      else if (action === 'exportJson') {
        return {
          action: "export",
          class: "btn-success",
          label: "Export",
          onAction: this.onClickExportJson
        }
      }

      return action
    },

    getDeleteMessage(row) {
      return this.model.types.delete && this.model.types.delete.message && this.model.types.delete.message(row) || `${row.host_name || row._id}(을)를 삭제하시겠습니까?`
    },

    getCancelMessage(row) {
      return this.model.types.cancel && this.model.types.cancel.message && this.model.types.cancel.message(row) || `${row.uid || row._id}(을)를 취소하시겠습니까?`
    },

    onSearch( keyword, filter ) {
      this.loadData({ page: 1, keyword: keyword, filter: filter })
      this.page = 1;
    },

    onPage({ page }) {
      this.requestData({ page })
    },

    async onChangeGroup({ label, value }) {
      this.$data.groupId = value
      this.requestData({page : 1, size: 10})
    },

    onChangeExport(e) {
      let exportDays = parseInt(e.target.value)
      if (exportDays > 0) {
        this.$data.exportDateStart = this.$moment().subtract(exportDays - 1, 'days').format('YYYY-MM-DD')
        this.$data.exportDateEnd = this.$moment().format('YYYY-MM-DD')
      }
      else {
        this.$data.exportDateStart = ''
        this.$data.exportDateEnd = ''
      }
    },
    
    onClickDelete(row) {
      this.$dialog
        .confirm(this.getDeleteMessage(row), {
          okText: 'Delete',
          cancelText: 'Cancel',
        })
        .then((dialog) => {
          this.onDelete(row)
        })
        .catch((e) => {
          this.$toast.error(e.message)
        })
    },

    onDelete(row) {
  
      if (!(this.model.types.hostUnLinked && this.model.types.hostUnLinked.action)) {
        this.$toast.error('Delete Action 이 정의되지 않았습니다.')
        return
      }

      this.$store.dispatch(this.model.types.hostUnLinked.action, row)
        .then((data) => {
          this.$toast.success("삭제되었습니다.")
          this.loadData()
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    toggleUtil(action) {
      if (action === 'excel') {
        this.$data.showExport = !this.showExport
      }

      if (action === 'json') {
        this.$data.showExport = !this.showExport
      }
    },

    // async onClickExportJson(){
    //   let res = await this.$store.dispatch(this.model.types.exportJson.action)
    //   let data = {}, value

    //   const download = require('downloadjs')
      
    //   res.items.forEach( o => {
    //     value = JSON.parse(o.value)

    //     data[o.key] = value
    //   })
      
    //   data = new Blob([JSON.stringify(data, null, 2)], {
    //       type: 'application/json',
    //       name: 'language.json'
    //   });
      
    //   download(data, "language.json", "application/json");
    // }

  },
  components: {
    Pills, SearchInput, Pagination, FilterValue
  }
}
</script>