<template>
  <div class="search-input">

    <div class="input-group">
      <div class="input">
        <input @input="onChange" type="text" :value="value" class="form-control" placeholder="Search..." v-on:keyup.enter="onSubmit" @change="onChange" />
        <button type="button" class="btn" @click="onClear" v-if="keyword != ''"><fa-icon icon="times" class="icon" /></button>
      </div>
      <span class="input-group-append">
        <button type="button" class="btn btn-submit" @click="onSubmit">검색</button>
      </span>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: this.value || ''
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange(e) {
      this.$data.keyword = e.target.value
      this.$emit("input", this.keyword)
    },
    onClear(e) {
      this.$data.keyword = ""
      this.$emit("input", "")
      this.$emit("submit", this.keyword)
    },
    onSubmit(e) {
      this.$emit("submit", this.keyword)
    }
  }
}
</script>
