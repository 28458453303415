<template>
<span class="filter-value">
  <template v-if="(filter == 'all' || name == filter) && keyword" v-for="(part, index) in parts">
    <template v-if="part == '' && index > 0"><em>{{keyword}}</em></template>
    <template v-if="part == '' && index == 0"></template>
    <template v-if="part != '' && index > 0"><em>{{keyword}}</em>{{part}}</template>
    <template v-if="part != '' && index == 0">{{part}}</template>
  </template>
  <template v-if="!((filter == 'all' || name == filter) && keyword)">
    {{value}}
  </template>
</span>
</template>

<script>
export default {
  name: "filter-value",
  props: {
    name: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: ''
    },
    keyword: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
  },
  data() {
    let parts = this.keyword && this.value ? this.value.split(this.keyword) : []

    return {
      parts: parts
    }
  },
  watch: {
    'value'() {
      this.onChange()
    },
    'name'() {
      this.onChange()
    },
    'filter'() {
      this.onChange()
    },
    'keyword'() {
      this.onChange()
    }
  },
  methods: {
    onChange() {
      let keyword = this.keyword
      let value = this.value
      try {
        this.$data.parts = keyword && value ? value.split(keyword) : []
      } catch (e) {
        this.$data.parts = []
      }

      // console.log('this.$data.parts', this.name, this.filter, keyword, value, JSON.stringify(this.$data.parts), this.$data.parts.length)
    }
  }
}
</script>
